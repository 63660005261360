<template>
  <svg
    class="icon-custom"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <text
      x="-2"
      y="12"
    >Az</text>
  </svg>
</template>

<script>
export default {
  name: 'IconAz',
}
</script>
