<template>
  <b-tooltip
    v-if="isCreateMenuDisabledWithoutPlan || isCreateMenuDisabledWithPlan"
    :target="target"
    :triggers="triggers"
  >
    <div class="pt-50 pb-50">
      <span>
        <span v-if="isCreateMenuDisabledWithoutPlan">{{ $t('To create additional menus you need to become a subscriber') }}</span>
        <span v-if="isCreateMenuDisabledWithPlan">{{ $t('To create additional menus you need to upgrade your subscription') }}</span>
      </span>
      <b-button
        block
        size="sm"
        class="mt-50"
        variant="primary"
        @click="$router.push({ name: 'billing' })"
      >
        {{ isSubscriptionInactive ? $t('Start Free Trial') : $t('Upgrade') }}
      </b-button>
    </div>
  </b-tooltip>
</template>

<script>
import {
  BButton,
  BTooltip,
} from 'bootstrap-vue'
import mixinTooltipCreateMenu from '@/mixins/tooltip/tooltip-create-menu'

export default {
  name: 'TooltipCreateMenu',
  components: {
    BButton,
    BTooltip,
  },
  mixins: [
    mixinTooltipCreateMenu,
  ],
  props: {
    target: {
      type: String,
      required: true,
      default: '',
    },
    triggers: {
      type: String,
      required: true,
      default: '',
    },
  },
}
</script>
